<template>
  <div class="game-wrapper">
    <ul class="game-list desktop">
      <li v-for="(game, index) of centerGames" :key="index" :class="{ 'game-list--top': game.class === 'final' }">
        <GameItem :game="game" class-name="favorite" :is-highlight="index == 0 || index == (centerGames.length - 1)" />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { getGameCenter } from '~/resources/home'
import { useJackpot } from '~/composables/game/useJackpot'
const GameItem = defineAsyncComponent(() => import('~/components/desktop/pages/home/game-center/game-center-item.vue'))
const { HOME_SPORTS } = getGameCenter()
const { fetchSumJackpot } = useJackpot()
const centerGames = reactive(HOME_SPORTS)
onMounted(() => {
  fetchSumJackpot()
})
</script>
<style lang="scss" scoped src="~/public/assets/scss/components/desktop/pages/home/game-center/list.scss"></style>
